
export default {
  mixins: [],
  props: ['termin'],
  data() {
    return {}
  },
  computed: {
    fehler() {
      if (this.termin.gesamtzahl_verordnungsstunden && this.termin.zahl_auf_verordnung > this.termin.gesamtzahl_verordnungsstunden) return true;
      return false;
    },
    endeNahe() {
      if (this.termin.gesamtzahl_verordnungsstunden && this.termin.zahl_auf_verordnung >= this.termin.gesamtzahl_verordnungsstunden - 2) return true;
      return false;
    }
  },
  watch: {},
  methods: {},
  mounted() {},

}
